import React, { useEffect, useRef, useState } from "react";
import { format, isValid, parse } from "date-fns";
import { DayPicker } from "react-day-picker";
import DateTimeZone from '../../utils/date-time-zone';
import './styles.scss';

const strFormat = "MM/dd/yyyy";

const DatePickerInput = ({ onDayChange, value }) => {
  const dialogRef = useRef(null);
  const dialogId = `dialog-${value}`;
  const headerId = `header-${value}`;

  // Hold the month in state to control the calendar when the input changes
  const [month, setMonth] = useState(new DateTimeZone());

  // Hold the selected date in state
  const [selectedDate, setSelectedDate] = useState(value);
  // Hold the input value in state
  const [inputValue, setInputValue] = useState(value);

  // Hold the dialog visibility in state
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Function to toggle the dialog visibility
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
   if (value && value !== '') {
     setInputValue(value);
   }
  }, [value]);

  // Hook to handle the body scroll behavior and focus trapping.
  useEffect(() => {
    const handleBodyScroll = (isOpen) => {
      document.body.style.overflow = isOpen ? "hidden" : "";
    };
    if (!dialogRef.current) return;
    if (isDialogOpen) {
      handleBodyScroll(true);
      dialogRef.current.showModal();
    } else {
      handleBodyScroll(false);
      dialogRef.current.close();
    }
    if (!value || value === '') {
      setInputValue('');
    }
    return () => {
      handleBodyScroll(false);
    };
  }, [isDialogOpen]);

  const handleDayPickerSelect = (date) => {
    if (!date || date === '') {
      setInputValue("");
      setSelectedDate(undefined);
      onDayChange(null);
    } else {
      setSelectedDate(date);
      setInputValue(format(date, strFormat));
      onDayChange(format(date, strFormat));
    }
    if (!dialogRef.current) return;
    dialogRef.current.close();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // keep the input value in sync
    onDayChange(value);
    const parsedDate = parse(value, strFormat, new DateTimeZone());

    if (isValid(parsedDate)) {
      setSelectedDate(parsedDate);
      setMonth(parsedDate);
    } else {
      setSelectedDate(undefined);
    }
  };

  // const handleOnblur = () => {
  //   if (!value) return
  //   const parsed = parse(value, "dd/MM/yyyy", new DateTimeZone());
  //   if (!isValid(parsed)) {
  //     setInputValue("");
  //     setSelectedDate(undefined);
  //     onDayChange('');
  //   }
  // }

  return (
    <div className="day-input-picket">
      <input
        style={{ fontSize: "inherit", width: '200px', height: '30px', fontSize: '16px', marginTop: '10px' }}
        id="date-input"
        type="text"
        value={value}
        placeholder={"mm/dd/yyyy"}
        onChange={handleInputChange}
      />{" "}
      <button
        style={{ fontSize: "16px", width: '30px', height: '25px', background: 'transparent', marginLeft: '-32px' }}
        onClick={toggleDialog}
        aria-controls="dialog"
        aria-haspopup="dialog"
        aria-expanded={isDialogOpen}
        aria-label="Open calendar to choose booking date"
      >
        <i class="fa fa-calendar" style={{ color: 'black' }} aria-hidden="true"></i>
      </button>
    
      <dialog
        role="dialog"
        ref={dialogRef}
        id={dialogId}
        aria-modal
        aria-labelledby={headerId}
        onClose={() => setIsDialogOpen(false)}
      >
        <DayPicker
          month={month}
          onMonthChange={setMonth}
          autoFocus
          mode="single"
          selected={selectedDate}
          onSelect={handleDayPickerSelect}
          footer={
            selectedDate !== undefined &&
            `Selected: ${selectedDate ? format(selectedDate, 'd MMM yyyy') : 'Empty'}`
          }
        />
      </dialog>
    </div>
  );
}

export default DatePickerInput;