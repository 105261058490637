module.exports = {
  INVESTMENT_TYPES: {
    INVESTOR_FUND: 'investorFund',
    LIMITED_PARTNER_FUND: 'limitedPartnerFund',
  },
  FUND_TYPES: {
    LIMITED_PARTNER: {
      id: 'partnership',
      label: 'Limited Partnership Agreement',
      displayName: 'Limited Partnership',
    },
    LIMITED_PARTNER_ABBR: {
      id: 'partnership_abbr',
      label: 'Limited Partnership Agreement',
      displayName: 'Short Form Limited Partnership',
    },
    LIMITED_LIABILITY_COMPANY: {
      id: 'liability',
      label: 'Limited Liability Company Agreement',
      displayName: 'Limited Liability Company',
    },
    LIMITED_PARTNERSHIP_MULTI_FUND: {
      id: 'limited_partnership_multi-fund',
      label: 'Limited Partnership Agreement',
      displayName: 'Limited Partnership Multi-Fund',
    },
    LIMITED_PARTNER_MULTI_FUND: {
      id: 'partnership_abbr_multi-fund',
      label: 'Limited Partnership Agreement',
      displayName: 'Short Form Multi-Fund',
    },
    ACCEL: {
      id: 'accel',
      label: 'Limited Partnership Agreement',
      displayName: 'Accel Leaders',
    },
    ACCELMAIN: {
      id: 'accel-main',
      label: 'Limited Partnership Agreement',
      displayName: 'Accel Main',
    },
    ACCEL_ENTREPRENEURS: {
      id: 'accel-entrepreneurs',
      label: 'Limited Partnership Agreement',
      displayName: 'Accel Entrepreneurs',
    },
    ACCEL_ENTREPRENEURS_RETURNING: {
      id: 'accel-entrepreneurs-returning',
      label: 'Limited Partnership Agreement',
      displayName: 'Accel Entrepreneurs Returning',
    },
    ACCEL_ENTREPRENEURS_RETURNING_MULTI_FUND: {
      id: 'accel-entrepreneurs-returning-multi-fund',
      label: 'Limited Partnership Agreement',
      displayName: 'Accel Entrepreneurs Returning Multi-Fund',
    },
    AUDERE: {
      id: 'audere',
      label: 'Limited Partnership Agreement',
      displayName: 'Audere',
    },
    BITKRAFT: {
      id: 'bit-kraft',
      label: 'Limited Partnership Agreement',
      displayName: 'BITKRAFT',
    },
    BITKRAFT_INDIA: {
      id: 'bitkraft-india',
      label: 'Limited Partnership Agreement',
      displayName: 'BITKRAFT India',
    },
    CAYMAN: {
      id: 'cayman',
      label: 'Limited Partnership Agreement',
      displayName: 'Cayman - Maples',
    },
    FIREWORK: {
      id: 'firework',
      label: 'Limited Partnership Agreement',
      displayName: 'Firework',
    },
    FORESITE: {
      id: 'foresite',
      label: 'Amended and Restated Limited Partnership Agreement',
      displayName: 'Foresite',
    },
    FORESITELABS: {
      id: 'foresiteLabs',
      label: 'Limited Partnership Agreement',
      displayName: 'Foresite Labs',
    },
    HCM: {
      id: 'HCM',
      label: 'Limited Partnership Agreement',
      displayName: 'HCM',
    },
    INITIALIZED: {
      id: 'initialized',
      label: 'Limited Partnership Agreement',
      displayName: 'Initialized',
    },
    IOLAR: {
      id: 'iolar',
      label: 'Limited Partnership Agreement',
      displayName: 'Iolar',
    },
    OSAGE: {
      id: 'osage',
      label: 'Limited Partnership Agreement',
      displayName: 'Osage',
    },
    PROMETHEAN: {
      id: 'promethean',
      label: 'Limited Partnership Agreement',
      displayName: 'Promethean',
    },
    QUESTACAPITAL: {
      id: 'questacapital',
      label: 'Limited Partnership Agreement',
      displayName: 'Questa Capital',
    },
    TCG_CROSSOVER: {
      id: 'tcg-crossover',
      label: 'Limited Partnership Agreement',
      displayName: 'TCG Crossover',
    },
    NEW_LIMITED_PARTNER: {
      id: 'new-partnership',
      label: 'Limited Partnership Agreement',
      displayName: 'New Limited Partnership',
    },
    ELEPHANT_PARTNER: {
      id: 'elephant_partner',
      label: 'Limited Partnership Agreement',
      displayName: 'LP with Form PF', //('Elephant Partners')
    },
    SR_ONE: {
      id: 'sr-one',
      label: 'Limited Partnership Agreement',
      displayName: 'SR One',
    },
    FRAZIER: {
      id: 'frazier',
      label: 'Limited Partnership Agreement',
      displayName: 'Frazier',
    },
    TERRARIUM: {
      id: 'terrarium',
      label: 'Limited Partnership Agreement',
      displayName: 'Terrarium',
    },
    DCVC: {
      id: 'dcvc',
      label: 'Limited Partnership Agreement',
      displayName: 'DCVC',
    },
  },
};
