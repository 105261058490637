module.exports = {
	LimitedPartnerV1: 'LimitedPartnerV1',
	LimitedPartnerV2: 'LimitedPartnerV2',
	LimitedPartnerV3: 'LimitedPartnerV3',
	LimitedPartnerV4: 'LimitedPartnerV4',
	LimitedPartnerV5: 'LimitedPartnerV5',
	LimitedPartnerV6: 'LimitedPartnerV6',
	LimitedPartnerV7: 'LimitedPartnerV7',
	LimitedPartnerV8: 'LimitedPartnerV8',
	LimitedPartnerV8_1: 'LimitedPartnerV8_1',

	// Versioning by new fund created
	LimitedPartnerV9: 'LimitedPartnerV9',
	LimitedPartnerV10: 'LimitedPartnerV10',
	LimitedPartnerV10_1: 'LimitedPartnerV10_1',
	LimitedPartnerV11: 'LimitedPartnerV11',
	LimitedPartnerV12: 'LimitedPartnerV12',

	LimitedLiabilityCompanyV1: 'LimitedLiabilityCompanyV1',
	LimitedLiabilityCompanyV2: 'LimitedLiabilityCompanyV2',
	LimitedLiabilityCompanyV3: 'LimitedLiabilityCompanyV3',
	LimitedLiabilityCompanyV4: 'LimitedLiabilityCompanyV4',
	LimitedLiabilityCompanyV5: 'LimitedLiabilityCompanyV5',
	LimitedLiabilityCompanyV6: 'LimitedLiabilityCompanyV6',
	LimitedLiabilityCompanyV6_1: 'LimitedLiabilityCompanyV6_1',

	// Versioning by new fund created
	LimitedLiabilityCompanyV7: 'LimitedLiabilityCompanyV7',
	LimitedLiabilityCompanyV8: 'LimitedLiabilityCompanyV8',
	LimitedLiabilityCompanyV8_1: 'LimitedLiabilityCompanyV8_1',
	LimitedLiabilityCompanyV9: 'LimitedLiabilityCompanyV9',
	LimitedLiabilityCompanyV10: 'LimitedLiabilityCompanyV10',

	ForesiteV1: 'ForesiteV1',
	ForesiteV2: 'ForesiteV2',
	ForesiteV3: 'ForesiteV3',
	ForesiteV4: 'ForesiteV4',
	ForesiteV5: 'ForesiteV5',
	ForesiteV6: 'ForesiteV6',
	ForesiteV7: 'ForesiteV7',
	ForesiteV8: 'ForesiteV8',

	QuestaCapitalV1: 'QuestaCapitalV1',
	QuestaCapitalV2: 'QuestaCapitalV2',
	QuestaCapitalV3: 'QuestaCapitalV3',
	QuestaCapitalV4: 'QuestaCapitalV4',
	QuestaCapitalV5: 'QuestaCapitalV5',
	QuestaCapitalV6: 'QuestaCapitalV6',
	QuestaCapitalV7: 'QuestaCapitalV7',
	QuestaCapitalV8: 'QuestaCapitalV8',

	InitializedV1: 'InitializedV1',
	InitializedV2: 'InitializedV2',
	InitializedV3: 'InitializedV3',
	InitializedV4: 'InitializedV4',
	InitializedV5: 'InitializedV5',
	InitializedV6: 'InitializedV6',
	InitializedV7: 'InitializedV7',
	InitializedV8: 'InitializedV8',

	HCMV1: 'HCMV1',
	HCMV2: 'HCMV2',
	HCMV3: 'HCMV3',
	HCMV4: 'HCMV4',
	HCMV5: 'HCMV5',

	ForesiteLabsV1: 'ForesiteLabsV1',
	ForesiteLabsV2: 'ForesiteLabsV2',
	ForesiteLabsV3: 'ForesiteLabsV3',
	ForesiteLabsV4: 'ForesiteLabsV4',
	ForesiteLabsV5: 'ForesiteLabsV5',

	LPABBRV1: 'LPABBRV1',
	LPABBRV1_1: 'LPABBRV1_1',

	// Versioning by new fund created
	LPABBRV2: 'LPABBRV2',
	LPABBRV3: 'LPABBRV3',
	LPABBRV3_1: 'LPABBRV3_1',
	LPABBRV4: 'LPABBRV4',
	LPABBRV5: 'LPABBRV5',

	IOLARV1: 'IOLVARV1',
	IOLARV2: 'IOLVARV2',
	IOLARV3: 'IOLVARV3',

	OSAGEV1: 'OSAGEV1',
	OSAGEV2: 'OSAGEV2',
	OSAGEV3: 'OSAGEV3',

	NewLimitedPartnerV1: 'NewLimitedPartnerV1',
	NewLimitedPartnerV2: 'NewLimitedPartnerV2',
	NewLimitedPartnerV3: 'NewLimitedPartnerV3',

	TcgCrossOverV1: 'TcgCrossOverV1',
	TcgCrossOverV2: 'TcgCrossOverV2',
	TcgCrossOverV3: 'TcgCrossOverV3',

	BITKRAFTV1: 'BITKRAFTV1',
	BITKRAFTV2: 'BITKRAFTV2',
	BITKRAFTV3: 'BITKRAFTV3',

	BitkraftIndiaV1: 'BitkraftIndiaV1',

	ElephantPartnerV1: 'ElephantPartnerV1',
	ElephantPartnerV2: 'ElephantPartnerV2',
	ElephantPartnerV3: 'ElephantPartnerV3',

	FireworkV1: 'FireworkV1',
	FireworkV2: 'FireworkV2',
	FireworkV3: 'FireworkV3',

	PrometheanV1: 'PrometheanV1',
	PrometheanV2: 'PrometheanV2',
	PrometheanV3: 'PrometheanV3',

	AccelV1: 'AccelV1',
	AccelV2: 'AccelV2',
	AccelV3: 'AccelV3',
	AccelV4: 'AccelV4',

	AccelMainV1: 'AccelMainV1',
	AccelMainV2: 'AccelMainV2',

	AccelEntrepreneursV1: 'AccelEntrepreneursV1',
	AccelEntrepreneursV2: 'AccelEntrepreneursV2',

	AccelEtpnReturningV1: 'AccelEtpnReturningV1',
	AccelEtpnReturningV2: 'AccelEtpnReturningV2',

	AccelEtpnReturningMultiV1: 'AccelEtpnReturningMultiV1',

	//Short MultiFund
	LPMULTIFUNDV1: 'LPMULTIFUNDV1',

	// Versioning by new fund created
	LPMULTIFUNDV2: 'LPMULTIFUNDV2',
	LPMULTIFUNDV2_1: 'LPMULTIFUNDV2_1',
	LPMULTIFUNDV3: 'LPMULTIFUNDV3',
	LPMULTIFUNDV4: 'LPMULTIFUNDV4',

	LimitedPartnershipMFV1: 'LimitedPartnershipMFV1',
	LimitedPartnershipMFV2: 'LimitedPartnershipMFV2',
	LimitedPartnershipMFV2_1: 'LimitedPartnershipMFV2_1',
	LimitedPartnershipMFV3: 'LimitedPartnershipMFV3',
	LimitedPartnershipMFV4: 'LimitedPartnershipMFV4',

	AudereV1: 'AudereV1',
	AudereV2: 'AudereV2',
	AudereV3: 'AudereV3',

	CaymanV1: 'CaymanV1',
	CaymanV2: 'CaymanV2',
	CaymanV3: 'CaymanV3',
	CaymanV4: 'CaymanV4',

	SROneV1: 'SROneV1',
	SROneV2: 'SROneV2',

	FrazierV1: 'FrazierV1',
	FrazierV2: 'FrazierV2',

	TerrariumV1: 'TerrariumV1',
	
	DCVCV1: 'DCVCV1',
};
