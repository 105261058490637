/* eslint-disable react/sort-comp, react-hooks/exhaustive-deps */
/* @flow */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as utility from './../../../utils/utility';
import * as notify from './../../../utils/notify';
import './styles.scss';
import CollapseTrigger from './../../../components/CollapseTrigger/index';
import CollapseContainer from './../../../components/CollapseContainer/index';
import ScrollableContainer from './../../../components/ScrollableContainer/index';
import InlineSubmit from './../../../components/InlineSubmit/index';
import Access from './../../../components/Access/index';
import Alert from './../../../components/Alert/index';
import MultiAccessLogin from './../../../components/MultiAccessLogin/index';
import Paragraph from './../../../components/Paragraph';
import Button from './../../../components/Button';
import Dropdown from './../../../components/Dropdown';
import * as action from '../../FundForm/action';

import {
  QuestionnaireExperience as QuestionnaireExperienceContext,
  OrganizationContext as OrganizationContextType,
  Context as ContextType,
  Reducer,
  Dispatch,
} from './../../../types/index';
import {
  IQ_SET_FUND,
  IQ_SET_INVESTOR,
  IQ_SET_INVESTOR_FUND,
  IQ_IS_INITIALIZATION_COMPLETE,
  IQ_IS_CURRENT_CLOSED_STATUS,
  IQ_SET_CURRENT_STEP,
  IQ_IS_MULTI_ACCESS,
  IQ_SET_AUTHENTICATION_STEP,
  IQ_IS_SIGNATURES_ENABLED,
  IQ_SET_VALIDATION_REQUIRED_PARTS,
  IQ_SHOW_SIGNATURE_INSTRUCTIONS,
  SET_ROLE_USER,
} from '../reducer';
import {
  API_URL,
  SERVICE_CONFIG,
  SERVICE_CONFIG_ORGANIZATION,
} from '../../../utils/service-commons';
import { iq_cb_token, iq_cb_user_token } from '..';
import { confirmDocCanBeSigned, confirmReturningInvestorCanSign } from '../util';
import { AccelEtpnReturningV1, AccelEtpnReturningV2, AccelEtpnReturningMultiV1, TerrariumV1 } from 'utils/constants/questionnaire/versions';

type Props = {
  history: Object,
  questionnaireContext: QuestionnaireExperienceContext,
  organizationContext: OrganizationContextType,
  context: ContextType,
  selectFund: () => void,
  initAccess: () => void,
  verifyAccessCode: () => void,
  adminQuestionnaireAccess: () => void,
  adminOrganizationQuestionnaireAccess: () => void,
  organizationDashboardQuestionnaireAccess: () => void,
  validatePreviousAccess: () => void,
  initMultiIQLP: () => void,
  notifyIE: () => {},
  invalidEmailNotification: () => {},
  disableLogin: () => {},
  loginMultiIQAccess: () => {},
  getFundById: () => {},
};

const handleTerrariumInvestor = (data) => {
  if (!data) {
    return false;
  }
  const investor = data.limitedPartnerFund || data.investorFund;
  
  if (!investor || !investor.questionnaire || !investor.questionnaire.iqVersion) {
    return false;
  }

 return investor.questionnaire.iqVersion.includes(TerrariumV1)
       
};

const handleReturningInvestor = (data) => {
  if (!data) {
    return false;
  }
  const investor = data.limitedPartnerFund || data.investorFund;
  
  if (!investor || !investor.questionnaire || !investor.questionnaire.iqVersion) {
    return false;
  }

 return investor.questionnaire.iqVersion.includes(AccelEtpnReturningV1) || 
        investor.questionnaire.iqVersion.includes(AccelEtpnReturningV2) ||
        investor.questionnaire.iqVersion.includes(AccelEtpnReturningMultiV1);
};

const Login = (props) => {
  const [investorEmail, setInvestorEmail] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [selectedLimitedPartner, setSelectedLimitedPartner] = useState('empty');
  const [disabledAccess, setDisabledAccess] = useState(false);

  const verificationMessage = '';

  useEffect(() => {
    if (
      document.title !== 'Fund Formation Portal - Fund Formation Portal Login'
    ) {
      notify.hideNotification();
    }
    // fixme should trigger this only if accessing the
    // questionnaire directly to avoid the user who
    // does both , end up in an IQ that is not relevant
    validatePreviousQuestionnaireAccess();
    let payload = null;
    if (utility.getUrlParameter('f')) {
      fetchFund(utility.getUrlParameter('f'));
    } else if (utility.getUrlParameter('if')) {
      initAdminAccess(utility.getUrlParameter('if'));
      // adminQuestionnaireAccess(utility.getUrlParameter('if'), history);
    } else if (utility.getUrlParameter('lptab')) {
      if (utility.getUrlParameter('userToken')) {
        payload = {
          limitedPartner: utility.getUrlParameter('lp'),
          fund: utility.getUrlParameter('fund'),
          iqUserToken: utility.getUrlParameter('userToken'),
        };
        initMultiAccess(payload);
      }
    } else if (utility.getUrlParameter('lpf')) {
      if (utility.getUrlParameter('orgUser')) {
        payload = {
          limitedPartnerFund: utility.getUrlParameter('lpf'),
          organizationUser: utility.getUrlParameter('orgUser'),
          token: utility.getUrlParameter('token'),
        };
        initViaOrganizationUserDashboardAccess(payload);
      } else {
        initAdminOrganizationAccess(utility.getUrlParameter('lpf'));
      }
    }
    if (window) {
      if (
        navigator.userAgent.indexOf('MSIE ') > -1 ||
        navigator.userAgent.indexOf('Trident') > -1 ||
        /MSIE 10/i.test(navigator.userAgent) ||
        /MSIE 9/i.test(navigator.userAgent) ||
        /rv:11.0/i.test(navigator.userAgent)
      ) {
        props.notifyIE();
        props.disableLogin();
      }
    }
  }, []);

  const verifyQuestionnaireIsClosed = (_payload) =>
    new Promise((resolve, reject) => {
      axios
        .post(`${API_URL()}/access/closedcheck`, _payload, {})
        .then((closedCheckResp) => {
          if (closedCheckResp.data.success) {
            if (closedCheckResp.data.closed) {
              props.isQuestionnaireClosed(true);
              resolve(true);
            } else {
              resolve(false);
            }
          }
        });
    });

  const initAccess = async (_fund, _email) => {
    try {
      const { data } = await axios.post(`${API_URL()}/access/`, {
        fund: _fund,
        email: _email,
      });

      if (data.success) {
        props.setInvestor(data.investor);
        const isClosed = await verifyQuestionnaireIsClosed({
          investor: data.investor._id,
          fund: _fund,
        });
        if (!isClosed) {
          props.setAuthenticationStep(2);
        }
      } else {
        props.setAuthenticationStep(0);
        props.isQuestionnaireClosed(true);
      }
    } catch (err) {
      props.renderNotification('error', 'Error', err);
    }
  };

  const initMultiAccess = async (_payload) => {
    const { history } = props;
    const { data } = await axios.post(
      `${API_URL()}/access/multi/`,
      _payload,
      {}
    );
    if (data.success) {
      const accelReturningInvestor = handleReturningInvestor(data);
      const terrariumInvestor = handleTerrariumInvestor(data);
      props.setFund(data.limitedPartnerFund.fund);
      props.setOrganization(data.organization);
      props.setOrganizationUser(data.user);
      props.setInvestor(data.limitedPartner);
      props.isMultiAccess(true);
      props.isInitializationComplete(true);
      props.setInvestorFund(data.limitedPartnerFund);
      if (typeof window !== 'undefined') {
        sessionStorage.setItem(iq_cb_user_token, data.iqUserToken);
        sessionStorage.setItem(iq_cb_token, data.accessToken);
      }
      if (accelReturningInvestor) {
        history.push('/access/home/information');
      } else if (terrariumInvestor) {
        history.push('/access/home/information');
      } else {
        history.push('/access/home/instructions');
      }
    } else {
      props.isQuestionnaireClosed(true);
    }
  };

  const loginMultiAccess = async (_payload) => {
    try {
      const { data } = await axios.post(
        `${API_URL()}/iqUsers/login/`,
        _payload,
        {}
      );
      if (data.success) {
        const user = data.user;
        if (user.verified) {
          if (data.user && data.user.organization) {
            sessionStorage.setItem(iq_cb_user_token, data.token);
            props.setOrganization(data.organization);
            props.setOrganizationUser(data.user);
            props.isMultiAccess(true);
            props.setAuthenticationStep(3);
          } else {
            props.renderNotification(
              'warning',
              'No organization assigned',
              'User is not associated with any organization, please create one'
            );
          }
        } else {
          props.renderNotification(
            'warning',
            'Verification Missing',
            'User is not verified, please follow the link in your email'
          );
        }
      } else if (!data.success) {
        props.renderNotification('error', 'Login failed', data.message);
      }
    } catch (err) {
      props.renderNotification('error', 'Login failed', err);
    }
  };

  const initAdminAccess = async (_investorFund) => {
    const { history } = props;
    const payload = { investorFund: _investorFund };
    const isClosed = await verifyQuestionnaireIsClosed(payload);
    if (!isClosed) {
      const { data } = await axios.post(
        `${API_URL()}/access/admin`,
        payload,
        SERVICE_CONFIG()
      );
      if (data.success) {
        const accelReturningInvestor = handleReturningInvestor(data);
        const terrariumInvestor = handleTerrariumInvestor(data);
        sessionStorage.setItem('user-admin', 'admin');
        props.setFund(data.investorFund.fund);
        props.setUserRole('admin');
        props.setInvestor(data.investorFund.investor);
        props.isInitializationComplete(true);
        props.setInvestorFund(data.investorFund);
        sessionStorage.setItem(iq_cb_token, data.accessToken);
        if (accelReturningInvestor) {
          history.push('/access/home/information');
        } else if (terrariumInvestor) {
          history.push('/access/home/information');
        } else {
          history.push('/access/home/instructions');
        }
      }
    }
  };

  const initAdminOrganizationAccess = async (_limitedPartnerFund) => {
    const { history } = props;
    const payload = { limitedPartnerFund: _limitedPartnerFund };
    const isClosed = await verifyQuestionnaireIsClosed(payload);
    if (!isClosed) {
      const { data } = await axios.post(
        `${API_URL()}/access/admin`,
        payload,
        SERVICE_CONFIG()
      );
      
      if (data.success) {
        const accelReturningInvestor = handleReturningInvestor(data);
        const terrariumInvestor = handleTerrariumInvestor(data);
        sessionStorage.setItem('user-admin', 'admin');
        props.setFund(data.limitedPartnerFund.fund);
        props.setInvestor(data.limitedPartnerFund.limitedPartner);
        props.isInitializationComplete(true);
        props.setUserRole('admin');
        props.isMultiAccess(true);
        props.setInvestorFund(data.limitedPartnerFund);
        sessionStorage.setItem(iq_cb_token, data.accessToken);
        if (accelReturningInvestor) {
          history.push('/access/home/information');
        } else if (terrariumInvestor) {
          history.push('/access/home/information');
        }  else {
          history.push('/access/home/instructions');
        }
      }
    }
  };

  const initViaOrganizationUserDashboardAccess = async (_payload) => {
    const { history } = props;
    const closedQuery = { limitedPartnerFund: _payload.limitedPartnerFund };
    const isClosed = await verifyQuestionnaireIsClosed(closedQuery);
    if (!isClosed) {
      const { data } = await axios.post(
        `${API_URL()}/access/organization/admin`,
        _payload,
        SERVICE_CONFIG_ORGANIZATION()
      );
      if (data.success) {
        const accelReturningInvestor = handleReturningInvestor(data);
        const terrariumInvestor = handleTerrariumInvestor(data);
        props.setFund(data.limitedPartnerFund.fund);
        props.setOrganization(data.organization);
        props.setOrganizationUser(data.user);
        props.setInvestor(data.limitedPartner);
        props.isMultiAccess(true);
        props.isInitializationComplete(true);
        props.setInvestorFund(data.limitedPartnerFund);
        sessionStorage.setItem(iq_cb_user_token, data.iqUserToken);
        sessionStorage.setItem(iq_cb_token, data.accessToken);
        if (accelReturningInvestor) {
          history.push('/access/home/information');
        } else if (terrariumInvestor) {
          history.push('/access/home/information');
        }  else {
          history.push('/access/home/instructions');
        }
      }
    }
  };

  const fetchFund = async (fund) => {
    const { data } = await axios.get(
      `${API_URL()}/fund/min/${fund}`,
      SERVICE_CONFIG()
    );
    props.setFund(data.fund);
    if (data.fund.disabledAccess && !disabledAccess) {
      setDisabledAccess(true);
      toast.warn(
        'Limited access. Questions? Please contact us at fundportaladmin@gunder.com',
        {
          position: 'top-right',
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'toast-general-look',
        }
      );
    }
  };

  const validatePreviousQuestionnaireAccess = async () => {
    const IQSessionToken = sessionStorage.getItem(iq_cb_token);
    if (IQSessionToken) {
      const UserSession = sessionStorage.getItem(iq_cb_user_token);
      const { data } = await axios.post(`${API_URL()}/access/refresh`, {
        accessToken: IQSessionToken,
        iqUserToken: UserSession,
      });

       const isAccel = data.limitedPartnerFund && 
          data.limitedPartnerFund.fund &&
          data.limitedPartnerFund.fund.fundType  &&
          data.limitedPartnerFund.fund.fundType.identifier === 'accel';

      const terrariumSubscribedPerson = data.investorFund && data.investorFund.generalInfo && 
        data.investorFund.generalInfo.terrariumSubscribedPerson;

      const accelReturningInvestor = handleReturningInvestor(data);
      const terrariumInvestor = handleTerrariumInvestor(data);

      if (data.success) {
        if (typeof window !== 'undefined') {
          if (sessionStorage.getItem('user-admin')) {
            props.setUserRole('admin');
          }
        }
        if (data.limitedPartner && data.limitedPartnerFund) {
          props.setFund(data.limitedPartnerFund.fund);
          if (data.user) {
            props.setOrganization(data.user.organization);
            props.setOrganizationUser(data.user);
          }

          props.setInvestor(data.limitedPartner);
          props.isMultiAccess(true);
          props.isInitializationComplete(true);
          props.setInvestorFund(data.limitedPartnerFund);
        } else if (data.investorFund) {
          props.setFund(data.investorFund.fund);
          props.setInvestor(data.investorFund.investor);
          props.isInitializationComplete(true);
          props.setInvestorFund(data.investorFund);
        }
        const { history } = props;
        const baseRedirect = '/access/home';

        if (isAccel) {
          switch (sessionStorage.getItem('iq-last-step')) {
            case '0':
              history.push(`${baseRedirect}/instructions`);
              break;
            case '1':
              history.push(`${baseRedirect}/letter`);
              break;
            case '2':
              history.push(`${baseRedirect}/information`);
              break;
            case '3':
              history.push(`${baseRedirect}/questionnaire`);
              break;
            case '4':
              await confirmDocCanBeSigned(props);
              history.push(`${baseRedirect}/signatures`);
              break;
            default:
              history.push(`${baseRedirect}/instructions`);
              break;
          }
        } else if (accelReturningInvestor) {
          switch (sessionStorage.getItem('iq-last-step')) {
            case '0':
              history.push(`${baseRedirect}/information`);
              break;
            case '1':
              confirmReturningInvestorCanSign(props);
              history.push(`${baseRedirect}/signatures`);
              break;
            default:
                history.push(`${baseRedirect}/information`);
              break;
          }
        } else if (terrariumInvestor) {
          
          if (terrariumSubscribedPerson === 'yes') {
            switch (sessionStorage.getItem('iq-last-step')) {
            case '0':
              history.push(`${baseRedirect}/information`);
              break;
            case '1':
               confirmReturningInvestorCanSign(props); 
              history.push(`${baseRedirect}/signatures`);
              break;
            default:
              history.push(`${baseRedirect}/information`);
              break;
          }
          } else {
            switch (sessionStorage.getItem('iq-last-step')) {
              case '0':
                history.push(`${baseRedirect}/instructions`);
                break;
              case '1':
                history.push(`${baseRedirect}/information`);
                break;
              case '2':
                history.push(`${baseRedirect}/questionnaire`);
                break;
              case '3':
                await confirmDocCanBeSigned(props);
                history.push(`${baseRedirect}/signatures`);
                break;
              default:
                history.push(`${baseRedirect}/information`);
                break;
            }
          } 
        }
        else {
          switch (sessionStorage.getItem('iq-last-step')) {
            case '0':
              history.push(`${baseRedirect}/instructions`);
              break;
            case '1':
              history.push(`${baseRedirect}/information`);
              break;
            case '2':
              history.push(`${baseRedirect}/questionnaire`);
              break;
            case '3':
              await confirmDocCanBeSigned(props);
              history.push(`${baseRedirect}/signatures`);
              break;
            default:
              history.push(`${baseRedirect}/instructions`);
              break;
          }
        }
        const iq_last_step = sessionStorage.getItem('iq-last-step');
        const lastAccessed = iq_last_step ? Number(iq_last_step) : 0;
        props.setCurrentStep(lastAccessed);
      }
    }
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    const { questionnaireContext, invalidEmailNotification } = props;
    if (investorEmail || investorEmail !== '') {
      initAccess(questionnaireContext.fund._id, investorEmail);
    } else {
      invalidEmailNotification();
    }
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    setInvestorEmail(event.target.value);
  };

  const handleAccessChange = (event) => {
    event.preventDefault();
    setAccessCode(event.target.value);
  };

  const handleAccessCodeSubmit = async (event) => {
    event.preventDefault();
    const { history, questionnaireContext } = props;
    const { data } = await axios.post(
      `${API_URL()}/access/verify/`,
      {
        accessCode: accessCode,
        fund: questionnaireContext.fund._id,
        investorEmail: investorEmail,
      },
      {}
    );
    if (data.success) {
      const accelReturningInvestor = handleReturningInvestor(data);
      const terrariumInvestor = handleTerrariumInvestor(data);
      props.isInitializationComplete(true);
      props.setInvestorFund(data.investorFund);
      sessionStorage.setItem(iq_cb_token, data.accessToken);
      if (accelReturningInvestor) {
        history.push('/access/home/information');
      }
      else if (terrariumInvestor) {
        history.push('/access/home/information');
      } else {
        history.push('/access/home/instructions');
      }
    } else {
      props.renderNotification('error', 'Invalid Access Code', data.message);
    }
  };

  const resendAccessCode = (event) => {
    const { questionnaireContext } = props;
    event.preventDefault();
    //eslint-disable-next-line
    if (window.confirm('Are you sure you want a new access code ?')) {
      initAccess(questionnaireContext.fund._id, investorEmail, () => {});
    }
  };

  const renderScrollableContainer = (content) => (
    <ScrollableContainer containerContent={content} />
  );

  const renderCollapseContainer = (content, collapsed) => (
    <CollapseContainer containerContent={content} collapsed={collapsed} />
  );

  const renderCollapseTrigger = () => <CollapseTrigger />;

  const generateScrollableContent = () => {
    const { questionnaireContext } = props;
    const collapseContent = generateCollapseContent();
    const collapseTrigger =
      questionnaireContext.authenticationStep === 2
        ? renderCollapseTrigger()
        : null;
    const render =
      questionnaireContext.authenticationStep === 2 ||
      questionnaireContext.authenticationStep === 3;
    const collapseContainer = renderCollapseContainer(collapseContent, render);
    const isMultiFund = questionnaireContext.fund.fundType.value === 'partnership_abbr_multi-fund' || 
                        questionnaireContext.fund.fundType.value === 'limited_partnership_multi-fund' ||
                        questionnaireContext.fund.fundType.value === 'accel-entrepreneurs-returning-multi-fund';

    const fund3legalName = questionnaireContext.fund && questionnaireContext.fund.fund3LegalName;
    const FundNames = isMultiFund && questionnaireContext.fund && questionnaireContext.fund.fund2LegalName ? 
    `${!fund3legalName ? ' and' : ','} ${questionnaireContext.fund.fund2LegalName} ${fund3legalName ? 'and ' + fund3legalName : ''}` : '';

    const content = [
      <div key="scrollable-content-head" className="fund-head">
        {questionnaireContext.fund.fundLegalName ? (
          <span>
            <h6
              key="scrollable-content-head-title"
              className="color-dark-green"
            >
              TO THE PROSPECTIVE INVESTORS OF{' '}
              <span className="fund-legal-name-ds-text">
                {questionnaireContext.fund.fundLegalName}
                {FundNames}
              </span>
            </h6>
            {collapseTrigger}
            {collapseContainer}
          </span>
        ) : (
          <span>
            <h6>No associated fund found</h6>
            <br />
          </span>
        )}
      </div>,
    ];
    return content;
  };
  const generateCollapseContent = () => {
    const { questionnaireContext } = props;
    const isMultiFund = questionnaireContext.fund.fundType.value === 'partnership_abbr_multi-fund' || 
          questionnaireContext.fund.fundType.value === 'limited_partnership_multi-fund' ||
          questionnaireContext.fund.fundType.value === 'accel-entrepreneurs-returning-multi-fund';
          
    const fund3legalName = questionnaireContext.fund && questionnaireContext.fund.fund3LegalName;
    const FundNames = isMultiFund && questionnaireContext.fund && questionnaireContext.fund.fund2LegalName ? 
    `${!fund3legalName ? ' and' : ','} ${questionnaireContext.fund.fund2LegalName} ${fund3legalName ? 'and ' + fund3legalName : ''}` : '';

    const theFund = fund3legalName ? '(each, a “Fund” and together, the “Funds”)' : isMultiFund ? '(the “Funds”)' : '(the “Fund”)'
    
    const content = [
      <p key="collapse-cont-1">
        Re: Formation of {questionnaireContext.fund.fundLegalName}{FundNames} {theFund}
      </p>,
      <p key="collapse-cont-2" className="pre-styled">
        {questionnaireContext.fund.coverLetter}
      </p>,
    ];
    return content;
  };

  const multiAccessLogin = (event) => {
    event.preventDefault();
    const getValue = (identifier) => document.getElementById(identifier).value;
    const payload = {
      email: getValue('username-input').toLowerCase(),
      password: getValue('password-input'),
    };
    loginMultiAccess(payload);
  };

  const selectLimitedPartner = (event) => {
    event.preventDefault();
    const selectedLPId = event.target.value;
    setSelectedLimitedPartner(selectedLPId);
  };

  const initMultiAccessQuestionnaire = (event) => {
    event.preventDefault();
    const { organizationContext, questionnaireContext } = props;
    let foundLP = null;
    if (selectedLimitedPartner !== '') {
      foundLP = organizationContext.organization.limitedPartners.find(
        (orgLP) => orgLP._id.toString() === selectedLimitedPartner
      );
      if (typeof window !== 'undefined' && foundLP) {
        const queryParam = `lp=${encodeURIComponent(
          foundLP._id
        )}&fund=${questionnaireContext.fund._id.toString()}&userToken=${sessionStorage.getItem(
          iq_cb_user_token
        )}&lptab=1`;
        window.open(
          `/access/login/?${queryParam}${event.target.getAttribute(
            'data-identifier'
          )}`,
          '_blank'
        );
      }
    }
  };

  // step 3
  const renderMultiAccessLoginStart = () => {
    const { organizationContext } = props;

    const limitedPartners =
      organizationContext.organization.limitedPartners.map((lp) => ({
        id: lp._id.toString(),
        value: lp.name,
      }));

    return (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-block text-center">
          <div className="access-select-sec-container">
            <div>
              <h4>{organizationContext.organization.name}</h4>
              <Paragraph>
                <p className="par-format">
                  Choose the Investor for which you are filling out this
                  Investor Questionnaire. Additional Investors can be added from
                  your account.
                  <span />
                  <a href="/organization/limitedPartners" target="_blank">
                    {' '}
                    Create an Investor
                  </a>
                  .
                </p>
              </Paragraph>
              <div className="row">
                <div className="col-lg-12 center-block ">
                  <Dropdown
                    inputData={{
                      id: 'selectedLimitedPartner',
                      value: selectedLimitedPartner,
                      displayName: 'Select Investor',
                      title: '',
                      size: 'large',
                      options: limitedPartners,
                    }}
                    onChangeHandler={selectLimitedPartner}
                  />
                </div>
                <br />
              </div>
              <a
                className="button"
                href="#/"
                id="login-input-multi-iq-access-start"
                type="submit"
                onClick={initMultiAccessQuestionnaire}
              >
                Start
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const { context, questionnaireContext } = props;
  const disabledInputs = !context.canLogIn;

  const emailSubmit = questionnaireContext.authenticationStep === 1 && (
    <InlineSubmit
      inputData={{
        displayName: '',
        disabled: disabledInputs || questionnaireContext.fund.disabledAccess,
      }}
      clickHandler={handleEmailSubmit}
      onChangeHandler={handleEmailChange}
    />
  );

  const _accessCode = questionnaireContext.authenticationStep === 2 && (
    <Access
      mailTo={investorEmail}
      clickHandler={handleAccessCodeSubmit}
      onChangeHandler={handleAccessChange}
      initAccess={resendAccessCode}
    />
  );

  return (
    <div className="main-container">
      {/* <Alert
        inputData={{
          type: context.notificationType,
          title: context.title,
          message: context.message,
          showAlways: context.showAlways,
        }}
      /> */}
      <div className="container">
        <div className="row">
          <Helmet title="Fund Formation Portal Login" />
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="fund-container" style={{ marginTop: '-50px' }}>
              {questionnaireContext.fund.fundLegalName &&
              !questionnaireContext.investorFundClosed ? (
                <div className="fund-content">
                  {renderScrollableContainer(generateScrollableContent())}
                </div>
              ) : null}
              {!questionnaireContext.fund.fundLegalName &&
              questionnaireContext.investorFundClosed &&
              verificationMessage === '' ? (
                <h4 className="color-dark-green fund-notFound-message text-center">
                  <br />
                  <br />
                  <br />
                  Please follow the link sent to you via email to access your
                  investor questionnaire.
                </h4>
              ) : null}
              {verificationMessage !== '' ? (
                <h4 className="color-dark-green fund-notFound-message text-center">
                  {verificationMessage}
                </h4>
              ) : null}
              {questionnaireContext.investorFundClosed ? (
                <div>
                  <h4 className="color-dark-green fund-notFound-message text-center">
                    <br />
                    <br />
                    <br />
                    If you have any questions about your previously submitted
                    subscription documents, please contact {' '}
                    <a
                      className="color-dark-green fund-notFound-message text-center"
                      href="mailto:fundportaladmin@gunder.com"
                    >
                      fundportaladmin@gunder.com
                    </a>
                  </h4>
                </div>
              ) : null}
              {questionnaireContext.fund.fundLegalName &&
              questionnaireContext.authenticationStep === 1 ? (
                <div style={{ marginTop: '-50px' }}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-xs-12">
                      <div className="access-sec-container custom-class-fund-login-single">
                        <p className="color-black">
                          <span className="color-dark-green">
                            <b>CONTINUE AS A GUEST (RECOMMENDED):</b>
                          </span>
                          &nbsp;FILL OUT A SINGLE INVESTOR QUESTIONNAIRE.
                        </p>
                        {questionnaireContext.fund.fundLegalName &&
                        !questionnaireContext.investorFundClosed ? (
                          <div className="col-lg-10 no-padding">
                            {emailSubmit}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <span className="or-middle-vertical-lines">
                      <b>OR</b>{' '}
                    </span>
                    <div className="col-lg-6 col-md-6 col-xs-12">
                      <div className="access-sec-container custom-class-fund-login-multiple">
                        <div className="row">
                          {questionnaireContext.fund.fundLegalName &&
                          !questionnaireContext.investorFundClosed ? (
                            <MultiAccessLogin
                              multiAccessLoginHandler={multiAccessLogin}
                              disabledInputs={
                                questionnaireContext.fund.disabledAccess
                              }
                            />
                          ) : null}
                          <div className="info-box-multiple-section">
                            <p>
                              NOTE: You{' '}
                              <span className="color-dark-green">
                                <b>must</b>
                              </span>{' '}
                              create an account if you will be completing
                              Investor Questionnaires for{' '}
                              <span className="color-dark-green">
                                <b>more than one investor</b>
                              </span>{' '}
                              in the Fund. <br />
                              Technical Questions? Please contact us at{' '}
                              <a href="mailto:fundportaladmin@gunder.com">
                                fundportaladmin@gunder.com
                              </a>{' '}
                              for technical questions only. For legal questions,
                              please contact the Gunderson Dettmer legal team.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="col-lg-12 col-md-12 col-xs-12">{_accessCode}</div>
              {questionnaireContext.authenticationStep === 3 &&
                renderMultiAccessLoginStart()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: Reducer) => ({
  organizationContext: state.organizationContext,
  context: state.context,
  questionnaireContext: state.questionnaireExperience,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    showSignaturesInstructions: (_show) =>
      dispatch({
        type: IQ_SHOW_SIGNATURE_INSTRUCTIONS,
        showInstructionsModal: _show,
      }),
    setFund: (_fund) => dispatch({ type: IQ_SET_FUND, fund: _fund }),
    setInvestor: (_investor) =>
      dispatch({ type: IQ_SET_INVESTOR, investor: _investor }),
    setInvestorFund: (_investorFund) =>
      dispatch({ type: IQ_SET_INVESTOR_FUND, investorFund: _investorFund }),
    setUserRole: (_userRole) =>
      dispatch({ type: SET_ROLE_USER, userRole: _userRole }),
    setCurrentStep: (_step) =>
      dispatch({ type: IQ_SET_CURRENT_STEP, currentStep: _step }),
    setAuthenticationStep: (_step) =>
      dispatch({ type: IQ_SET_AUTHENTICATION_STEP, authenticationStep: _step }),
    isSignaturesEnabled: (_enabled) =>
      dispatch({ type: IQ_IS_SIGNATURES_ENABLED, signaturesEnabled: _enabled }),
    isQuestionnaireClosed: (_isClosed) =>
      dispatch({
        type: IQ_IS_CURRENT_CLOSED_STATUS,
        investorFundClosed: _isClosed,
      }),
    isInitializationComplete: (_isComplete) =>
      dispatch({
        type: IQ_IS_INITIALIZATION_COMPLETE,
        initializationComplete: _isComplete,
      }),
    isMultiAccess: (_isMultiAccess) =>
      dispatch({ type: IQ_IS_MULTI_ACCESS, multiAccess: _isMultiAccess }),
    setValidationRequiredParts: (_requiredItems) =>
      dispatch({
        type: IQ_SET_VALIDATION_REQUIRED_PARTS,
        missingQuestionnaireParts: _requiredItems,
      }),
    renderNotification: (_type, _title, _message) =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: _type,
        message: _message,
        title: _title,
        timeout: 5000,
      }),
    setOrganization: (_organization) =>
      dispatch({ type: 'SET_ORGANIZATION', organization: _organization }),
    setOrganizationUser: (_user) =>
      dispatch({ type: 'SET_ORGANIZATION_USER', user: _user }),
    // new additions above
    notifyIE: () =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: 'Please change to Chrome or Firefox',
        title: 'You are using an unsupported browser',
        showAlways: true,
      }),
    invalidEmailNotification: () =>
      dispatch({
        type: 'RENDER_NOTIFICATION',
        notificationType: 'error',
        message: 'Please enter a valid email address',
        title: 'Invalid Email',
        timeout: 4000,
      }),
    // need to verify this
    disableLogin: () => dispatch({ type: 'SET_CAN_LOG_IN', canLogIn: false }),
    getFundById: (firmId, callback) =>
      dispatch(action.getFundById(firmId, callback)),
    // new items added above
  })
);
export default connector(Login);
