import type {
  Dispatch,
  GetState,
  ThunkAction
} from '../../types';
import { handleError } from './../../utils/requestErrorHandler';

export const SET_CONTENT = 'SET_CONTENT';
export const RESET_FORMS = 'RESET_FORMS';
export const SET_USER_FORM = 'SET_USER_FORM';
export const SET_INVITE_FORM = 'SET_INVITE_FORM';
export const SET_ORGANIZATION_FORM = 'SET_ORGANIZATION_FORM';
export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';

const API_URL = '/api';
function getConfig() {
  return {
    headers: { Authorization: `Bearer ${(typeof window !== 'undefined' && localStorage.getItem('organization-auth-token')) ? localStorage.getItem('organization-auth-token') : 'invalid'}` },
  };
}

export const inviteUser = (invitePayload): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.post(`${API_URL}/iqUsers/invite`, invitePayload, getConfig());
      document.getElementById('close-invite-modal').click();
        const state = getState();
      if (res.data.success) {
        const oldOrganization = state.organizationContext.organization;
        oldOrganization.iqUsers = res.data.organization.iqUsers;
        dispatch({ type: 'SET_ORGANIZATION', organization: oldOrganization });
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'success', message: res.data.message, title: res.data.title });
        dispatch({ type: 'SET_CONTENT', content: 'users' });
      } else {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'error', message: res.data.message, title: res.data.title });
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const editOrganization = (updatePayload): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.put(`${API_URL}/organizations/${updatePayload._id.toString()}/`, updatePayload, getConfig());
      const state = getState();
      if (res.data.success) {
        const oldOrganization = state.organizationContext.organization;
        res.data.organization.iqUsers = oldOrganization.iqUsers;
        res.data.organization.limitedPartners = oldOrganization.limitedPartners;

        dispatch({ type: 'SET_ORGANIZATION', organization: { oldOrganization, ...res.data.organization } });
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'success', message: res.data.message, title: 'Edit Success' });
        dispatch({ type: 'SET_CONTENT', content: 'organization' });
      } else {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'error', message: res.data.message, title: 'Something went wrong' });
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const editUserProfile = (updatePayload): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.put(`${API_URL}/iqUsers/`, updatePayload, getConfig());
      if (res.data.success) {
        // dispatch({ type: 'SET_ORGANIZATION', organization: res.data.organization });
        dispatch({ type: 'SET_ORGANIZATION_USER', user: res.data.user });
        dispatch({ type: 'SET_USER_FORM', fieldName: 'password', fieldProperty: 'value', fieldPropertyValue: '' });
        dispatch({ type: 'SET_USER_FORM', fieldName: 'confirmPassword', fieldProperty: 'value', fieldPropertyValue: '' });
        dispatch({ type: 'SET_USER_FORM', fieldName: 'matching', fieldProperty: 'error', fieldPropertyValue: false });
        dispatch({ type: 'SET_USER_FORM', fieldName: 'matching', fieldProperty: 'message', fieldPropertyValue: '' });
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'success', message: res.data.message, title: 'Edit Success' });
        dispatch({ type: 'SET_CONTENT', content: 'profile' });
        if (updatePayload.confirmPassword) {
          window.location.reload();
        }
      } else {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'error', message: res.data.message, title: 'Something went wrong' });
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const editOrganizationUser = (updatePayload): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.put(`${API_URL}/iqUsers/`, updatePayload, getConfig());
      document.getElementById('close-invite-modal').click();
      if (res.data.success) {
        dispatch({ type: 'SET_ORGANIZATION', organization: res.data.organization });
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'success', message: res.data.message, title: 'Edit Success' });
        dispatch({ type: 'SET_CONTENT', content: 'users' });
      } else {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'error', message: res.data.message, title: 'Something went wrong' });
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };

export const deleteOrganizationUser = (userId): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: any) => {
    try {
      const res = await axios.delete(`${API_URL}/iqUsers/${userId}`, getConfig());
       const state = getState();
      if (res.data.success) {
        const oldOrganization = state.organizationContext.organization;
        oldOrganization.iqUsers = res.data.organization.iqUsers;

        dispatch({ type: 'SET_ORGANIZATION', organization: oldOrganization });
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'success', message: res.data.message, title: 'Delete success' });
        dispatch({ type: 'SET_CONTENT', content: 'users' });
      } else {
        dispatch({ type: RENDER_NOTIFICATION, notificationType: 'error', message: res.data.message, title: 'Something went wrong' });
      }
      return res;
    } catch (err) {
      handleError(err, getState(), dispatch);
    }
  };
